<template>
  <footer v-if="usedVariant !== 'no-footer'" class="the-footer mt-2xl">
    <section class="flex flex-col gap-3xl py-xl bg-secondary100">
      <TheFooterRegisteredCourier v-if="usedVariant === 'full'" />
      <TheFooterLinkCollection v-if="!isNativeApp" gap="md" type="app" />
      <TheFooterLinkCollection gap="2xl" type="social" />
    </section>

    <section class="py-xl">
      <div class="content-wrap flex flex-col gap-lg px-xs">
        <TheFooterNavigation />

        <article v-if="content" class="text-center text-caption">
          <strong v-if="content.copyright">
            {{ content.copyright }}
          </strong>
          <p v-if="content.address">
            {{ content.address }}
          </p>
        </article>

        <TheFooterResponsibleGaming />
      </div>
    </section>
  </footer>
</template>

<script lang="ts" setup>
export type TheFooterVariant = 'condensed' | 'full' | false

type TheFooterProps = {
  variant?: TheFooterVariant
}

const props = withDefaults(defineProps<TheFooterProps>(), {
  variant: undefined,
})

const { data } = await useContentfulContent().getFooter()
const { isNativeApp } = useCustomContext()

const content = computed(() => {
  if (!data.value?.items[0]) return null
  const { addressLine, copyrightLine } = data.value?.items[0]

  return {
    address: addressLine ?? null,
    copyright: copyrightLine ?? null,
  }
})

const usedVariant = computed(() => {
  let { footer } = useRoute().meta
  if (typeof props.variant !== 'undefined') footer = props.variant

  if (typeof footer === 'undefined') return 'condensed'
  if (!footer) return 'no-footer'
  return footer
})
</script>

<style lang="scss" scoped>
.the-footer {
  > section:first-child {
    position: relative;

    > * + * {
      &::before {
        content: '';
        position: absolute;
        right: spacing('xs');
        left: spacing('xs');
        display: block;
        margin-top: -1 * spacing('lg');
        border-bottom: 1px solid color('secondary400');
      }
    }
  }
}
</style>
