<template>
  <nav v-if="links.length" class="the-footer-navigation">
    <ul class="flex flex-wrap">
      <li v-for="{ caption, target, to } in links" :key="caption">
        <HyperLink v-bind="{ target, to }">
          {{ caption }}
        </HyperLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
const { data } = await useContentfulContent().getFooter()

const links = computed(
  () =>
    // TODO Remove fallbacks when contentful schema finally gets it’s shit together
    data.value?.items[0]?.footerNavigationCollection?.items.map((item) => ({
      caption: item?.caption ?? '',
      target: item?.openInNewTab ? '_blank' : '_self',
      to: item?.url ?? '',
    })) ?? [],
)
</script>

<style lang="scss" scoped>
.the-footer-navigation {
  a {
    @include transition-default(background-color, color);

    @include hover-state {
      color: color('info700');
    }

    @include focus-state {
      z-index: 0;
      color: color('white');

      &::before {
        inset: -0.125em;
        z-index: -1;
        border-radius: $border-radius-sm;
        background-color: color('info700');
      }
    }

    position: relative;
  }

  ul {
    row-gap: spacing('xs');

    li {
      flex: 0 50%;
      padding: 0 spacing('xs');

      &:nth-of-type(odd) {
        text-align: end;
      }
    }

    @include media-desktop {
      gap: spacing('xs');
      justify-content: center;

      li {
        flex: none;

        &,
        &:nth-of-type(odd) {
          text-align: center;
        }
      }
    }
  }
}
</style>
