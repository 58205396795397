<template>
  <article
    v-if="content"
    class="content-wrap flex flex-col items-center gap-xs text-center"
  >
    <img
      v-if="content.logo"
      :alt="content.logo.title"
      class="logo -mt-xl"
      :height="content.logo.height"
      :src="content.logo.url"
      :width="content.logo.width"
    />

    <span class="text-h5">
      {{ content.headline }}
    </span>

    <p class="mt-xs text-caption">
      {{ content.caption }}
    </p>
  </article>
</template>

<script lang="ts" setup>
const { data } = await useContentfulContent().getFooter()

const content = computed(() => {
  if (!data.value?.items[0]?.registeredCourier) return null
  const { caption, headline, logo } = data.value?.items[0].registeredCourier

  // TODO Remove fallbacks when contentful schema finally gets it’s shit together
  return {
    caption: caption ?? '',
    headline: headline ?? '',
    logo: logo
      ? {
          height: logo.height ?? 0,
          title: logo.title ?? '',
          url: logo.url ?? '',
          width: logo.width ?? 0,
        }
      : null,
  }
})
</script>

<style lang="scss" scoped>
.logo {
  width: 6.25rem;
}
</style>
